import React, { useState } from "react";
import { Switch } from "@headlessui/react";

export default function DarkModeToggle({ stateChanger, dark }) {
  const toggleDarkMode = () => {
    if (dark) {
      localStorage.theme = "light";
      stateChanger(!dark);
    } else {
      localStorage.theme = "dark";
      stateChanger(!dark);
    }
  };

  return (
    <Switch.Group>
      <div className="flex justify-center items-center text-md text-gray-500 dark:text-slate-400">
        <Switch.Label passive>Dark mode</Switch.Label>

        <Switch
          checked={dark}
          onChange={toggleDarkMode}
          className={`${dark ? "bg-gray-700" : "bg-gray-200"}
          ml-2 relative inline-flex flex-shrink-0 h-[30px] w-[54px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${dark ? "translate-x-6" : "translate-x-0"}
            pointer-events-none inline-block h-[26px] w-[26px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
}
