//import * as React from "react";
import { Link, navigate } from "gatsby";
import ContactMenu from "./contactMenu";
import React, { useState, useEffect } from "react";
import DarkModeToggle from "./darkMode";
import { ChevronLeftIcon } from "@heroicons/react/outline";

import { Phone } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout({ children, location }) {
  const [dark, setDark] = useState(false);

  useEffect(() => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      setDark(true);
    } else {
      setDark(false);
    }
  }, []);

  const regex = /^\/portfolio\/[0-9A-Za-z]/;
  if (location === "" && typeof window !== "undefined") {
    location = window.location.pathname;
  }

  //const path = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <div className={dark ? "dark" : ""}>
      <header className="md:sticky fixed bottom-0 md:top-0 z-40 w-full h-fit md:h-16 print:hidden backdrop-blur flex  lg:z-50  lg:border-gray-900/10 dark:border-gray-50/[0.06] bg-white/75 supports-backdrop-blur:bg-white/95 dark:bg-gray-900/75 ">
        <div className="max-w-screen-xl flex w-full items-center justify-between mx-auto md:px-4">
          {/* {regex.test(location) ? ( */}
          {location === "/shared/portfolioPage/" ? (
            <div className="flex">
              <button
                onClick={() => navigate(-1)}
                className="flex items-center font-semibold text-gray-900 dark:text-white border-2 border-transparent p-2 group rounded-lg"
              >
                <ChevronLeftIcon className="h-6 w-6 mr-2 group-hover:animate-wiggle text-interaction " />

                <span className="group-hover:text-transparent group-hover:transition group-hover:bg-clip-text group-hover:bg-gradient-to-br group-hover:from-[#49b6ff] group-hover:to-interaction">
                  Back
                </span>
              </button>
            </div>
          ) : location != "/homePage/" ? (
            <div className="flex w-full justify-between px-4 md:px-0 md:w-auto md:justify-start h-full md:h-auto items-center">
              <Link
                to="/shared/"
                key="homePage"
                className="flex items-center flex-col w-fit mr-1 group h-full py-2 md:py-0"
              >
                <HomeIcon
                  stroke={location === "/shared/homePage/" ? "url(#grad1)" : ""}
                />
                <div
                  className={classNames(
                    location === "/shared/homePage/"
                      ? "md:!text-gray-900 dark:md:!text-white !text-transparent bg-clip-text bg-gradient-to-br from-[#49b6ff] to-interaction"
                      : "",
                    "items-center md:p-2 text-xs md:text-base font-semibold transition-transform dark:text-gray-200 group-hover:transition  group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-br group-hover:from-[#49b6ff] group-hover:to-interaction"
                  )}
                >
                  Home
                </div>
                <div
                  className={classNames(
                    location === "/shared/homePage/" ? "!scale-100" : "",
                    "hidden md:flex w-full bg-gradient-to-br from-[#49b6ff] to-interaction h-0.5 rounded-full transition-transform scale-0 origin-center group-hover:transform group-hover:scale-100"
                  )}
                ></div>
              </Link>
              <Link
                to="/shared/cv/"
                key="CV"
                className="flex items-center flex-col w-fit mr-1 group h-full py-2 md:py-0"
              >
                <CvIcon
                  stroke={location === "/shared/cv/" ? "url(#grad1)" : ""}
                />
                <div
                  className={classNames(
                    location === "/shared/cv/"
                      ? "md:!text-gray-900 dark:md:!text-white !text-transparent bg-clip-text bg-gradient-to-br from-[#49b6ff] to-interaction"
                      : "",
                    "items-center md:p-2 text-xs md:text-base font-semibold transition-transform dark:text-gray-200 group-hover:transition  group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-br group-hover:from-[#49b6ff] group-hover:to-interaction"
                  )}
                >
                  CV
                </div>
                <div
                  className={classNames(
                    location === "/shared/cv/" ? "!scale-100" : "",
                    "hidden md:flex w-full bg-gradient-to-br from-[#49b6ff] to-interaction h-0.5 rounded-full transition-transform scale-0 origin-center group-hover:transform group-hover:scale-100"
                  )}
                ></div>
              </Link>
              <Link
                to="/shared/portfolio/"
                key="Portfolio"
                className="flex items-center flex-col w-fit mr-1 group h-full py-2 md:py-0"
              >
                <PortfolioIcon
                  stroke={
                    location === "/shared/portfolio/" ? "url(#grad1)" : ""
                  }
                />

                <div
                  className={classNames(
                    location === "/portfolio/"
                      ? "md:!text-gray-900 dark:md:!text-white !text-transparent bg-clip-text bg-gradient-to-br from-[#49b6ff] to-interaction"
                      : "",
                    "items-center md:p-2 text-xs md:text-base font-semibold transition-transform dark:text-gray-200 group-hover:transition  group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-br group-hover:from-[#49b6ff] group-hover:to-interaction"
                  )}
                >
                  Portfolio
                </div>
                <div
                  className={classNames(
                    location === "/shared/portfolio/" ? "!scale-100" : "",
                    "hidden md:flex w-full bg-gradient-to-br from-[#49b6ff] to-interaction h-0.5 rounded-full transition-transform scale-0 origin-center group-hover:transform group-hover:scale-100"
                  )}
                ></div>
              </Link>
              <Link
                to="/shared/play/"
                key="Play"
                className="flex items-center flex-col w-fit mr-1 group h-full py-2 md:py-0"
              >
                <CodeIcon
                  stroke={location === "/shared/play/" ? "url(#grad1)" : ""}
                />

                <div
                  className={classNames(
                    location === "/shared/play/"
                      ? "md:!text-gray-900 dark:md:!text-white !text-transparent bg-clip-text bg-gradient-to-br from-[#49b6ff] to-interaction"
                      : "",
                    "items-center md:p-2 text-xs md:text-base font-semibold transition-transform dark:text-gray-200 group-hover:transition  group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-br group-hover:from-[#49b6ff] group-hover:to-interaction"
                  )}
                >
                  Code
                </div>
                <div
                  className={classNames(
                    location === "/shared/play/" ? "!scale-100" : "",
                    "hidden md:flex w-full bg-gradient-to-br from-[#49b6ff] to-interaction h-0.5 rounded-full transition-transform scale-0 origin-center group-hover:transform group-hover:scale-100"
                  )}
                ></div>
              </Link>
              <div className="flex md:hidden h-full">
                <ContactMenu />
              </div>
            </div>
          ) : (
            "-"
          )}

          <div className="hidden md:flex">
            <ContactMenu />
          </div>
        </div>
      </header>
      <main>{children}</main>
      <footer className="w-full  bg-gray-50 dark:bg-gray-900 dark:text-[#94a3b8] print:hidden pb-16 md:pb-0">
        <div className="flex w-full mx-auto justify-center flex-col items-center border-t-2 border-gray-100 dark:border-gray-800 max-w-screen-xl py-6">
          <div className="text-sm text-gray-500 dark:text-slate-400 pb-4">
            © 2022 Ole Lillebo
          </div>
          <DarkModeToggle stateChanger={setDark} dark={dark} />
        </div>
      </footer>
    </div>
  );
}

/*
const navigation = [
  { name: "CV", link: "/cv/" },
  { name: "Portfolio", link: "/portfolio/" },
  { name: "Contact me", link: "/contact" },
];
{navigation.map((item) => (
    <Link
        to={item.link}
        activeClassName="bg-purple-500 text-white"
        partiallyActive={true}
        key={item.name}
        className="flex items-center text-sm p-2 group rounded-lg hover:bg-purple-500 hover:text-white "
    >
        {item.name}
    </Link>
))}*/

function HomeIcon(stroke) {
  return (
    <svg
      className={classNames(
        stroke && stroke.stroke ? "" : "dark:stroke-slate-200",
        "h-6 w-6 md:hidden transition-transform"
      )}
      fill="none"
      stroke={stroke && stroke.stroke ? stroke.stroke : "currentColor"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: "#49b6ff", stopOpacity: 1 }} />
          <stop
            offset="100%"
            style={{ stopColor: "#ff499e", stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
      />
    </svg>
  );
}

function CvIcon(stroke) {
  return (
    <svg
      className={classNames(
        stroke && stroke.stroke ? "" : "dark:stroke-slate-200",
        "h-6 w-6 md:hidden transition-transform"
      )}
      fill="none"
      stroke={stroke && stroke.stroke ? stroke.stroke : "currentColor"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: "#49b6ff", stopOpacity: 1 }} />
          <stop
            offset="100%"
            style={{ stopColor: "#ff499e", stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      />
    </svg>
  );
}

function CodeIcon(stroke) {
  return (
    <svg
      className={classNames(
        stroke && stroke.stroke ? "" : "dark:stroke-slate-200",
        "h-6 w-6 md:hidden transition-transform"
      )}
      fill="none"
      stroke={stroke && stroke.stroke ? stroke.stroke : "currentColor"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: "#49b6ff", stopOpacity: 1 }} />
          <stop
            offset="100%"
            style={{ stopColor: "#ff499e", stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
      />
    </svg>
  );
}

function PortfolioIcon(stroke) {
  return (
    <svg
      className={classNames(
        stroke && stroke.stroke ? "" : "dark:stroke-slate-200",
        "h-6 w-6 md:hidden transition-transform"
      )}
      fill="none"
      stroke={stroke && stroke.stroke ? stroke.stroke : "currentColor"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: "#49b6ff", stopOpacity: 1 }} />
          <stop
            offset="100%"
            style={{ stopColor: "#ff499e", stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  );
}
