import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import React, { Fragment } from "react";
import { ClipboardCopyIcon } from "@heroicons/react/outline";
import { StaticImage } from "gatsby-plugin-image";
import linkedInSvg from "../images/icons/linkedin.svg";
import whatsAppSvg from "../images/icons/whatsapp.svg";
import emailSvg from "../images/icons/mail.svg";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function ContactMenu() {
  const solutions = [
    {
      name: "LinkedIn",
      description: "linkedin.com/in/olelillebo",
      href: "http://www.linkedin.com/in/olelillebo",
      icon: linkedInSvg,
      copy: false,
    },
    {
      name: "Email",
      description: "lillebo@outlook.com",
      href: "mailto:lillebo@outlook.com",
      icon: emailSvg,
      copy: true,
    },
    {
      name: "WhatsApp",
      description: "+46 723945406",
      href: "https://wa.me/+46723945406",
      icon: whatsAppSvg,
      copy: true,
    },
  ];
  return (
    <div className="w-full max-w-sm">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? " text-black" : ""}
                md:bg-gradient-to-br from-[#49b6ff] to-interaction group rounded-lg md:p-0.5 `}
            >
              <div className="py-2 md:p-2 md:bg-white dark:md:bg-gray-900 dark:text-white rounded-md flex items-center font-semibold text-gray-900 transition-none md:transition duration-150 ease-in-out flex-col-reverse md:flex-row">
                <span
                  className={classNames(
                    open
                      ? "md:!text-gray-900 dark:md:!text-white text-transparent bg-clip-text bg-gradient-to-br from-[#49b6ff] to-interaction"
                      : "",
                    "text-xs md:p-0 md:text-base group-hover:text-transparent group-hover:transition group-hover:bg-clip-text group-hover:bg-gradient-to-br group-hover:from-[#49b6ff] group-hover:to-interaction"
                  )}
                >
                  Contact
                </span>

                <ChevronDownIcon
                  className={`${
                    open ? "transition ease-in-out rotate-180 " : ""
                  }
                  hidden md:flex md:ml-2 h-6 w-6 text-interaction transition ease-in-out duration-150  md:mt-0`}
                  aria-hidden="true"
                />
                <ContactIcon open={open} />
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="fixed bottom-16 md:bottom-auto md:absolute z-10 max-w-sm mt-3 transform right-0 px-0 ">
                <div className="overflow-hidden rounded-lg shadow-lg dark:shadow-sm dark:shadow-interaction ring-1 ring-black ring-opacity-5">
                  <div className="relative grid bg-white dark:bg-gray-800 dark:text-white p-2">
                    {solutions.map((item) => (
                      <div key={item.name} className="flex space-between">
                        <a
                          key={item.name}
                          href={item.href}
                          target="_blank"
                          className="flex p-2 group w-full items-center transition duration-150 ease-in-out"
                        >
                          <item.icon
                            aria-hidden="true"
                            class="h-8 w-8 flex group-hover:fill-interaction"
                          />

                          <div className="mx-2">
                            <div className="text-sm font-medium group-hover:text-transparent group-hover:transition group-hover:bg-clip-text group-hover:bg-gradient-to-br group-hover:from-[#49b6ff] group-hover:to-interaction">
                              {item.description}
                            </div>
                          </div>
                        </a>
                        <a
                          onClick={() => {
                            navigator.clipboard.writeText(item.description);
                          }}
                          className="p-2 flex items-center cursor-pointer"
                        >
                          {item.copy ? (
                            <CopyIcon
                              aria-hidden="true"
                              class="h-10 w-10 shrink-0 flex group-hover:fill-white"
                            />
                          ) : (
                            ""
                          )}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
function CopyIcon() {
  return (
    <svg
      className="h-5 w-5 hover:fill-black fill-gray-500 dark:fill-gray-400 hover:dark:fill-white"
      viewBox="0 0 48 48"
    >
      <path d="M0 0h48v48h-48z" fill="none" />
      <path d="M32 2h-24c-2.21 0-4 1.79-4 4v28h4v-28h24v-4zm6 8h-22c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4v-28c0-2.21-1.79-4-4-4zm0 32h-22v-28h22v28z" />
    </svg>
  );
}

function ContactIcon(open) {
  return (
    <svg
      className={classNames(
        open && open.open ? "" : "dark:stroke-slate-200",
        "h-6 w-6 md:hidden transition-transform"
      )}
      fill="none"
      stroke={open && open.open ? "url(#grad1)" : "currentColor"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: "#49b6ff", stopOpacity: 1 }} />
          <stop
            offset="100%"
            style={{ stopColor: "#ff499e", stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
      />
    </svg>
  );
}
